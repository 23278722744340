












import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";
import { Route, NavigationGuardNext } from "vue-router";

import PlacementForm from "@/settings/components/placements/PlacementForm.vue";
import PlacementRequestModel from "@/settings/models/placements/PlacementRequestModel";
import PlacementResponseModel from "@/settings/models/placements/PlacementResponseModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    PlacementForm,
  },
})
export default class PlacementView extends mixins(UnsavedChangesMixin) {
  localPlacement: PlacementRequestModel | null = null;
  isWatchedPlacement = false;

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localPlacement);
  }

  get placementId(): string {
    return this.$route.params.placementId;
  }

  get placement(): PlacementResponseModel {
    return this.$store.state.placementsStore.placement;
  }

  get isLoading(): boolean {
    return this.$store.state.placementsStore.isLoading;
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("appAdmin.placementManagement.editPlacementTitle")
    );
    this.$store.dispatch("loadPlacementById", this.placementId);
  }

  @Watch("placement", { deep: true })
  private watchPlacement(placement: PlacementResponseModel) {
    this.localPlacement = PlacementRequestModel.ofSource(placement);

    if (!this.isWatchedPlacement) {
      this.isWatchedPlacement = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  async handleSave(payload: PlacementRequestModel) {
    await this.$store.dispatch("updatePlacement", payload);
    this.isSavedForm = true;
    this.$router.push({
      name: AppSection.PLACEMENTS,
    });
  }
}
